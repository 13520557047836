import axios from "axios";
import { getJWT } from "./auth";

const api = axios.create({
    baseURL: process.env.GATSBY_STRAPI_BACKEND_URL,
    headers: {
        Authorization: "Bearer " + getJWT(),
    },
});

export const catcher = (error) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
    }
    console.log(error.config);
};

export default api;
