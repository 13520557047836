import * as React from "react";
import Layout from "../../components/layout";
import { Button, Container } from "react-bootstrap";
import api, { catcher } from "../../services/api";
import { getUser } from "../../services/auth";
import qs from "qs";

const TableRow = ({
    name,
    price,
    selected,
    onChange,
}: {
    name: string;
    price: number;
    selected: boolean;
    onChange: (e?) => void;
}) => {
    const check = React.useRef(null);
    return (
        <tr>
            <th scope="row">
                <input
                    type="checkbox"
                    onChange={(e) => onChange && onChange(e)}
                    checked={selected}
                />
            </th>
            <td>{name}</td>
            <td className="text-right">Rp{price?.toLocaleString("id")}</td>
        </tr>
    );
};

export default function CreateTransaction() {
    const [kas, setKas] = React.useState({ data: [] });
    const [transactions, setTransactions] = React.useState({ data: [] });
    const [total, setTotal] = React.useState(0);
    const [unpaidKases, setUnpaidKases] = React.useState([]);

    React.useEffect(() => {
        api.get("/transactions").then(setTransactions).catch(catcher);
        api.get("/categories?name=kas").then(setKas).catch(catcher);
    }, []);

    React.useEffect(() => {
        setUnpaidKases(
            kas.data?.[0]?.products
                .filter((product) => {
                    const paidKasId = transactions.data
                        .map((transaction) =>
                            transaction.products
                                .filter(
                                    (product) =>
                                        product.category === kas.data[0]?.id
                                )
                                .map((product) => product.id)
                        )
                        .reduce((acc, val) => [...acc, ...val], []);
                    return !paidKasId.includes(product.id);
                })
                .map((product) => {
                    product.selected = true;
                    return product;
                })
        );
    }, [kas, transactions]);

    return (
        <Layout title="Bayar kas" topNav>
            <Container>
                <table className="table border-right border-left">
                    <tbody>
                        {unpaidKases?.map((unpaidKas) => (
                            <TableRow
                                key={unpaidKas.id}
                                name={unpaidKas.name}
                                price={unpaidKas.price}
                                selected={unpaidKas.selected}
                                onChange={(e) => {
                                    console.log(e.target.checked);
                                    setUnpaidKases(
                                        unpaidKases.map((kas) => {
                                            if (kas.id === unpaidKas.id)
                                                kas.selected = e.target.checked;
                                            return kas;
                                        })
                                    );
                                }}
                            />
                        ))}
                        <tr className="bg-light border-bottom">
                            <th scope="row" colSpan={2}>
                                Total
                            </th>
                            <td className="text-right">
                                Rp
                                {unpaidKases
                                    ?.filter((val) => val.selected)
                                    .reduce((acc, val) => acc + val.price, 0)
                                    .toLocaleString("id")}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="text-right">
                    <Button
                        onClick={() => {
                            api.post("/transactions", {
                                status: "pendingPay",
                                payCodeSuffix: 42,
                                users_permissions_user: getUser().id,
                                products: unpaidKases?.filter(kas => kas.selected).map(kas => kas.id)
                            }).then(console.log).catch(catcher)
                        }}
                    >Bayar</Button>
                </div>
            </Container>
        </Layout>
    );
}
